@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  scrollbar-width: thin;
  font-family: "Montserrat", sans-serif;
}

.container {
  margin: 0;
  padding: 0;
  display: flex;
  position: fixed;
  font-family: "Montserrat", sans-serif;
}

main {
  width: 100%;
  padding: 20px;
}

.sidebar {
  position: fixed;
  top: 0;
  background: #2090ee;
  color: black;
  height: 100vh;
  transition: all 0s;
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.logo {
  font-size: 20px;
}

.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}

.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}

.link:hover {
  color: #000;
  transition: all 0.5s;
}

.active {
  color: #000;
}

.icon,
.link_text {
  font-size: 20px;
}

#name,
#customerid {
  width: 500px;
}

.scroll-section {
  max-height: 100px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.first {
  margin-left: 5%;
  margin-top: 50px;
  width: 30%;
  height: 700px;
  float: left;
  border: 2px solid black;
  text-align: center;
}

.dialog {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.second {
  margin-top: 50px;
  width: 65%;
  height: 700px;
  float: left;
  border: 2px solid black;
}

/* img {
  border-radius: 50%;
} */

.header {
  justify-content: center;
  display: flex;
  background: #2090ee;
  font-family: "Montserrat", sans-serif;
  /* font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; */
  font-weight: "bold";
  height: 3vh;
  font-size: large;
  color: #fff;
}

.newcus-doc {
  width: "57%";
  height: "500px";
  float: "left";
}

.date-picker-input {
  padding: 8px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
  margin-bottom: 10px;
}

.calendar-icon {
  position: absolute;
  top: 12px;
  right: 10px;
  pointer-events: none;
}

@media (min-width: 200px) and (max-width: 1300px) {
  .additional-right {
    width: "50%";
    height: "550px";
    float: left;
  }
}

.gender-form {
  height: 5vh;
  max-width: 400px;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .gender-form {
    max-width: 100%;
    padding: 0 20px;
  }
}

@media (min-width: 1300px) {
  .additional-right {
    width: 50%;
    height: 550px;
    float: right;
  }
}

@media (max-width: 800) {
  .customer-header {
    width: 100vh;
  }
}
