/* Profile.css */
/* @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

/* Ensure the grid container spans the full width */
.main-container {
  width: 100%;
  font-family: "Montserrat", sans-serif;
}

/* Ensure the grid items are displayed properly */
.card1 {
  display: flex;
  flex-direction: column;
  padding: 16px;
  margin-bottom: 30px;
  border: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 17px;
  -webkit-box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
  justify-content: start;
  font-family: "Montserrat", sans-serif;
  background-color: #fff;
}

.card1 h5 {
  font-family: "Montserrat", sans-serif;
  /* font-family: "Outfit", sans-serif; */
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.6px;
  margin-bottom: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: start;
}

.content label {
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #212529;
}

.message-number {
  font-size: 24px;
  font-weight: bold;
}

.message-text {
  margin-top: 8px;
  font-size: 16px;
}

.custom-textfield .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px dashed #6a71854d; /* Set border to dashed */
  border-radius: 0.375rem;
  padding: "10px 10px";
}

.custom-textfield
  .MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: inherit; /* Remove hover effect */
}

.custom-textfield
  .MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: inherit; /* Remove focus effect */
}

/* Add button */
.add-btn1 {
  display: flex;
  justify-content: end;
  width: 100%;
}

.add-btn1 Button {
  background-color: #3bd615;
  height: 40px;
  width: 182px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
  color: rgb(255, 255, 255);
  margin-top: 10px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.add-btn1 Button:hover {
  background-color: #57dd36;
}
