@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.containerc {
    max-height: calc(100vh - 150px); /* Adjust based on your header/footer height */
    overflow-y: auto;
    padding-right: 16px; /* To avoid scrollbar overlap */
    scrollbar-width: thin;
  }
  
.containerc input{
    height: 1em;
    /* border: 1px solid rgba(59, 214, 21, 0.3);
    border-radius: 5px;
    background-color: #f9f9f9; */
}
.containerc p{
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.containerc fieldset{
    border: 1px solid rgba(59, 214, 21, 0.3);
    border-radius: 5px;
    background-color: #f9f9f9;
    z-index: -1;
}

  /* profile image */
  .image-upload-containerc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #5ad63bd3;
    padding: 10px;
    border-radius: 10px;
    max-width: 255px;
  }
  
  .image-upload-containerc input[type="file"] {
    /* margin-bottom: 20px; */
    display: flex;
    justify-content: center;
    width: 50%;
    border: none;
  }
  .image-upload-containerc input[type="file"]::file-selector-button {
    display: none;
  }
  
  .image-preview {
    /* margin-top: 10px;
    display: flex;
    justify-content: center; */
    width: 224px;
    height: 110px;
    background-color: #D9D9D9;
    border-radius: 5px;
    margin: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image-preview img {
     max-width: 40%;
    /* border-radius: 10px; */
    /* max-width: 50%;
    height: auto; */
  }
  
  .custom-file-upload{
    margin-top: 1px;
  }

  .hidden {
    display: none;
  }

  /* Documents */

  /* .box1 {
    width: 100%;
    height: 165px;
    overflow-y: scroll;
    white-space: nowrap;
    scrollbar-color: #d9d9d9 #ffff;
    scrollbar-width: thin;
    display: flex;
    flex-direction: row;
  }

  display: "flex",
  flexDirection: "column",
  margin: "5px",
  height: "140px",
  
  .document {
    display: inline-block;
    width: 200px;
    max-width: 60%;
    height: 70%;
    border: 1px solid #d9d9d9;
    margin-right: 10px;
    padding: 10px;
    background-color: #d9d9d9;
    border-radius: 5px;
  } */

  .box-right {
    position: relative;
    /* border: 1px solid #d9d9d9;
      border-radius: 10px; */
    flex-grow: 2;
    /* max-width: 58rem; */
  }
  
  .box-right .doc {
    height: 20rem;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
  }
  
  .upload-btn {
    background-color: #3bd615;
    color: black;
    height: 45px;
    /* width: 13rem; */
    border-radius: 5px;
    align-items: end;
    justify-content: end;
    position: absolute;
    right: 20px;
    margin-top: 10px;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    padding: 18px;
    font-size: large;
  }
  
  .upload-btn input {
    display: none;
  }

  /* family member add css starts */

.family-mem {
  border: 1px solid #d9d9d9;
  max-width: 58rem;
  width: 100%;
  border-radius: 10px;
  height: 26rem;
  flex-grow: 1;
}

/* family member add css ends */