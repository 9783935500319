@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.containerl {
    max-height: calc(100vh - 150px); /* Adjust based on your header/footer height */
    overflow-y: auto;
    padding-right: 16px; /* To avoid scrollbar overlap */
    scrollbar-width: thin;
  }
  
.containerl input{
    height: 1em;
    /* border: 1px solid rgba(59, 214, 21, 0.3);
    border-radius: 5px;
    background-color: #f9f9f9; */
}
.containerl p{
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.containerl fieldset{
    border: 1px solid rgba(59, 214, 21, 0.3);
    border-radius: 5px;
    background-color: #f9f9f9;
    z-index: -1;
}

  /* profile image */
  .image-upload-containerl {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #5ad63bd3;
    padding: 10px;
    border-radius: 10px;
    max-width: 255px;
  }
  
  .image-upload-containerl input[type="file"] {
    /* margin-bottom: 20px; */
    display: flex;
    justify-content: center;
    width: 50%;
    border: none;
  }
  .image-upload-containerl input[type="file"]::file-selector-button {
    display: none;
  }
  
  .image-preview {
    /* margin-top: 10px;
    display: flex;
    justify-content: center; */
    width: 206px;
    height: 108px;
    background-color: #D9D9D9;
    border-radius: 5px;
    margin: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .image-preview img {
     max-width: 40%;
    /* border-radius: 10px; */
    /* max-width: 50%;
    height: auto; */
  }
  
  .custom-file-upload{
    margin-top: 1px;
  }

  .hidden {
    display: none;
  }

  /* Add button */
  .add-btn {
    display: flex;
    justify-content: end;
    width: 100%;
  }
  
  .add-btn Button{
      background-color: #3BD615;
      height: 52px;
      width: 182px;
      border-radius: 5px;
      font-size: 20px;
      font-weight: 600;
      color: rgb(255, 255, 255);
  }
  
  .add-btn Button:hover{
      background-color: #57dd36;
  }
  
