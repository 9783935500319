/* Import Google font - Poppins */

/* form css */
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
} */

/* body {
  min-height: 100vh;
  display: flex;
} */

/* all input size  */
input {
  height: 28px;
  text-align: left;
  background-color: #f9f9f9;
  border: 1px solid rgba(59, 214, 21, 0.3);
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
}

.containerx {
  position: relative;
  width: 51%;
  border-radius: 5px;
  /* border: 1px solid #d9d9d9; */
}

.containerx form {
  padding: 10px 35px;
  position: relative;
  min-height: 400px;
  width: 100%;
  margin-top: 10px;
  height: auto;
}

.containerx form .column {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.containerx form .column-1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: 56.3rem; */
}

.column .input-box-file {
  position: relative;
}

.column .btns {
  position: relative;
}
.column .btns .btn {
  /* padding: 6px 25px;
  background: rgb(59, 214, 21);
  border-radius: 5px;
  color: black; */
  background-color: rgb(59, 214, 21); 
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.column .btns .btn.selected {
  background-color: rgb(0, 255, 0); /* Selected red */
}

.column-1 .input-date {
  display: flex;
  width: 29.6rem;
}

.column-1 .input-box-1 {
  display: flex;
  width: 55rem;
}

.column .input-box-1 {
  display: flex;
  width: 19rem;
  justify-content: space-between;
}

.column .input-box-n {
  display: flex;
  justify-content: space-between;
  width: 30.9rem;
  margin: 15px 50px 10px 0px;
}

.input-box-n input {
  background-color: #f9f9f9;
  border: 1px solid rgba(59, 214, 21, 0.3);
  border-radius: 5px;
  width: 20rem;
  padding: 18px;
}

.column .input-box-date {
  display: flex;
  justify-content: space-between;
  width: 30.9rem;
  margin: 15px 10px 10px 0px;
}

.input-box-date input {
  background-color: #f9f9f9;
  border: 1px solid rgba(59, 214, 21, 0.3);
  border-radius: 5px;
  width: 20rem;
  padding: 18px;
}

.column-1 .input-box {
  display: flex;
  width: 570px;
  margin-top: 20px;
  justify-content: space-between;
}

.column-1 .input-date {
  display: flex;
  justify-content: space-between;
}

.form .input-box-name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 42rem;
}

.input-box-adr {
  width: 80%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.input-box-adr textarea {
  width: 80%;
  height: auto;
  padding: 18px;
  border: 1px solid rgba(59, 214, 21, 0.3);
  border-radius: 5px;
  background-color: #f9f9f9;
}

.input-date input {
  width: 300px;
  text-align: left;
  padding: 19px;
  background-color: #f9f9f9;
  border: 1px solid rgba(59, 214, 21, 0.3);
  border-radius: 5px;
  margin-top: 15px;
}

.input-box-name input {
  width: 650px;
  text-align: left;
  padding: 18px;
  background-color: #f9f9f9;
  border: 1px solid rgba(59, 214, 21, 0.3);
  border-radius: 5px;
  margin-top: 15px;
}

.column-1 .input-box select {
  height: 40px;
  /* padding: 18px; */
  background-color: #f9f9f9;
  border: 1px solid rgba(59, 214, 21, 0.3);
  border-radius: 5px;
  font-size: small;
  width: 10rem;
}

.input-box-1 input {
  width: 130px;
  text-align: center;
  background-color: #f9f9f9;
  border: 1px solid rgba(59, 214, 21, 0.3);
  border-radius: 5px;
  padding: 18px;
}

.input-box-1,
.input-box,
.input-box-name :focus {
  outline: none;
}

.input-date label {
  margin-top: 13px;
}

label {
  font-size: 15px;
  font-weight: 600;
  margin-top: 5px;
}

label img {
  height: 18px;
  width: 25px;
  display: inline-block;
  padding-right: 10px;
  padding-bottom: 2px;
}

label img {
  height: auto;
  width: 29px;
  display: inline-block;
  padding-right: 10px;
  padding-bottom: 2px;
}

input:not(:placeholder-shown) {
  color: black;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
}

.add-button {
  height: 2rem;
  width: auto;
  float: right;
  margin-bottom: 2rem;
  position: relative;
  right: 0;
}

.add-button Button {
  background-color: #3bd615;
  color: black;
  height: 2rem;
  width: 100%;
  border-radius: 10px;
}

/* .containerx {
    position: relative;
    max-width: 80rem;
    width: 100%;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
}

.containerx .form {
    margin-top: 0;
    margin: 10px 20px;
}

.form :where(.input-box) {
    width: 100%;
    margin-top: 10px;
}

.form :where(.input-box-1){
    width: 50%;
    margin-top: 10px;
}

.form :where(.input-box-2) {
    width: 100%;
    margin-top: 10px;
}
input, textarea{
    background-color: #f9f9f9;
}

.input-box label {
    font-weight: 600;
    color: #333;
}

.input-box-1 label {
    font-weight: 600;
    color: #333;
}

.form :where(.input-box input, .select-box) {
    position: relative;
    height: 38px;
    width: 100%;
    outline: none;
    font-size: 1rem;
    color: #707070;
    margin-top: 8px;
    border: 1px solid #3bd615;
    border-radius: 6px;
    padding: 0 20px;
}

.form :where(.input-box-1 input) {
    position: relative;
    height: 38px;
    width: 100%;
    outline: none;
    font-size: 1rem;
    color: #707070;
    margin-top: 8px;
    border: 1px solid #3bd615;
    border-radius: 6px;
    padding: 0 20px;
    flex-grow: 1;
}

.form :where(.input-box-2 input) {
    position: relative;
    height: 38px;
    width: 100%;
    outline: none;
    font-size: 1rem;
    color: #707070;
    margin-top: 8px;
    border: 1px solid #3bd615;
    border-radius: 6px;
    padding: 0 20px;
}

.form :where(.input-box textarea) {
    position: relative;
    height: 70px;
    width: 100%;
    outline: none;
    font-size: 1rem;
    color: #707070;
    margin-top: 8px;
    border: 1px solid #3bd615;
    border-radius: 6px;
    padding: 0 20px;
}

.input-box input:focus {
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}

.form .column {
    display: flex;
    column-gap: 10px;
}

.form .gender-box {
    margin-top: 10px;
}

.gender-box h3 {
    color: #333;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 8px;
}

.form :where(.gender-option, .gender) {
    display: flex;
    align-items: center;
    column-gap: 50px;
    flex-wrap: wrap;
}

.form .gender {
    column-gap: 5px;
}

.gender input {
    accent-color: rgb(130, 106, 251);
}

.form :where(.gender input, .gender label) {
    cursor: pointer;
}

.gender label {
    color: #707070;
}

.address :where(input, .select-box) {
    margin-top: 15px;
}

.select-box select {
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
    color: #707070;
    font-size: 1rem;
}

.form button:hover {
    background:#707070;
}


/*Responsive*/
@media screen and (max-width: 1200px) {
  .containerx {
    max-width: 400px;
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .form .column {
    flex-wrap: wrap;
  }

  .form :where(.gender-option, .gender) {
    row-gap: 15px;
  }

  .containerx {
    border: none;
  }
}

/* form css ends */

/* documents css starts */

.box-right {
  position: relative;
  /* border: 1px solid #d9d9d9;
    border-radius: 10px; */
  flex-grow: 2;
  max-width: 58rem;
}

.box-right .doc {
  height: 20rem;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.upload-btn {
  background-color: #3bd615;
  color: black;
  height: 45px;
  width: 13rem;
  border-radius: 5px;
  align-items: end;
  justify-content: end;
  position: absolute;
  right: 20px;
  margin-top: 10px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  padding: 18px;
  font-size: large;
}

.upload-btn input {
  display: none;
}

/* documents css ends */

/* family member add css starts */

.family-mem {
  border: 1px solid #d9d9d9;
  max-width: 58rem;
  width: 100%;
  border-radius: 10px;
  height: 26rem;
  flex-grow: 1;
}

/* family member add css ends */

.multiple-box {
  width: 500px;
  border: 1px solid black;
  margin: 0 auto;
}

.box1 {
  width: 100%;
  height: 165px;
  /* overflow-y: scroll; */
  overflow-x: hidden;
  white-space: nowrap;
  scrollbar-color: #d9d9d9 #ffff;
  scrollbar-width: thin;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.document {
  display: inline-block;
  width: 200px;
  max-width: 60%;
  height: 70%;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
  padding: 10px;
  background-color: #d9d9d9;
  border-radius: 5px;
}

/* Family member add */

.list-view {
  /* border: 1px solid #ddd; */
}

table {
  width: 200%;
  border-collapse: collapse;
  overflow-y: scroll; /* Enable vertical scrolling */
}

th,
td {
  padding: 10px;
  width: 95px;
  /* border: 1px solid #ddd; */
}

th {
  text-align: left;
}

/* submit btn */
/* style={{
    background:"rgb(59, 214, 21)",
    height:"52px", width:"182px",
     borderRadius:"5px",
      fontSize:"20px",
      fontWeight:"600"}} */

.add-btn {
  display: flex;
  justify-content: end;
  width: 100%;
}

.add-btn Button{
    background-color: #3BD615;
    height: 52px;
    width: 182px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 600;
    color: rgb(255, 255, 255);
}

.add-btn Button:hover{
    background-color: #57dd36;
}



/* profile image */
.image-upload-containerx {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 2px solid #5ad63bd3;
  padding: 10px;
  border-radius: 10px;
  max-width: 237px;
  width: 237px;
  height: 172px;
  max-height: 172px;
}

.image-upload-containerx input[type="file"] {
  /* margin-bottom: 20px; */
  display: flex;
  justify-content: center;
  width: 50%;
  border: none;
}
.image-upload-containerx input[type="file"]::file-selector-button {
  display: none;
}

.image-preview {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.image-preview img {
   max-width: 40%;
  /* border-radius: 10px; */
  /* max-width: 50%;
  height: auto; */
}

.custom-file-upload{
  margin-top: 1px;
}

.gender-radio-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #F9F9F9;
  border: 1px solid rgba(59, 214, 21, 0.3);
  border-radius: 5px;
  padding: 2px 5px;
  height: 2.7rem;
}

.gender-radio-group input[type="radio"] {
  margin-right: 5px;
}

.gender-radio-group label {
  margin-right: 15px;
}

.hidden {
  display: none;
}

