/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


.container {
  max-height: calc(
    100vh - 20px
  ); /* Adjust based on your header/footer height */
  overflow-y: auto;
  padding-right: 16px; /* To avoid scrollbar overlap */
  scrollbar-width: thin;
}

.container input {
  height: 1em;
  /* border: 1px solid rgba(59, 214, 21, 0.3);
    border-radius: 5px;
    background-color: #f9f9f9; */
}
.container p {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 16px;
}

.container fieldset {
  border: 1px solid rgba(59, 214, 21, 0.3);
  border-radius: 5px;
  background-color: #f9f9f9;
  z-index: -1;
}

/* Documents */
.box-right {
  position: relative;
  /* border: 1px solid #d9d9d9;
      border-radius: 10px; */
  flex-grow: 2;
  max-width: 58rem;
}

.box-right .doc {
  height: 20rem;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.upload-btn {
  background-color: #3bd615;
  color: black;
  height: 45px;
  width: 13rem;
  border-radius: 5px;
  align-items: end;
  justify-content: end;
  position: absolute;
  right: 20px;
  margin-top: 10px;
  display: inline-block;
  cursor: pointer;
  text-align: center;
  padding: 18px;
  font-size: large;
}

.upload-btn input {
  display: none;
}
