/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap'); */

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
}

.login-container .login-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  border: 1px solid #e8e8e8;
  background: white;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  padding: 3rem;
  width: auto;
}

.login-form-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-form-header {
  font-family: "Inter", sans-serif;
  text-align: center;
}

/* .home {
  top: 0;
  left: 0;
  height: auto;
  width: auto;
} */

.form {
  margin: 0 auto;
  /* padding-top: 80px; */
  /* height: calc(108vh - 80px - 125.5px); */
  background-color: #ffffffcc;
  padding-bottom: 0.3rem;
}

/* form box */
.form .form-box {
  position: absolute;
  left: 315px;
  width: 950px;
  height: 440px;
  border: 1px solid #e8e8e8;
  background: white;
  border-radius: 10px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
}

@media screen and (max-width: 1920px) {
  .form .form-box {
    position: absolute;
    left: 490px;
    width: 950px;
    height: 440px;
    border: 1px solid #e8e8e8;
    background: white;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  }
}
@media screen and (max-width: 1870px) {
  .form .form-box {
    position: absolute;
    left: 490px;
    width: 950px;
    height: 440px;
    border: 1px solid #e8e8e8;
    background: white;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  }
}
@media screen and (max-width: 1662px) {
  .form .form-box {
    position: absolute;
    left: 300px;
    width: 950px;
    height: 440px;
    border: 1px solid #e8e8e8;
    background: white;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  }
}

hr.hr-line {
  border: 1px solid #d9d9d9;
  height: 0px;
  width: 390px;
  left: 400px;
  top: 200px;
  position: absolute;
  transform: rotate(90deg);
}

/* sign in box */
.form-box .sign-in {
  position: absolute;
  width: 340px;
  height: 335px;
  left: 123px;
  top: 50px;
}

/* sign in header */
.sign-in .sign-in-header {
  position: absolute;
  width: 200px;
  left: 70px;
}

.sign-in-header p {
  color: #3bd615;
  font-weight: 600;
  font-style: normal;
  font-size: 30px;
  text-align: center;
  filter: none;
  font-family: "Inter", sans-serif;
}

.sign-in-header span {
  position: absolute;
  width: 200px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: black;
  letter-spacing: 0.004rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

/* input box of sign in */
.sign-in .sign-in-input {
  position: absolute;
  top: 100px;
  height: 150px;
}

.sign-in-input .input-user {
  width: 340px;
  height: 50px;
  border-radius: 10px;
  background: #f9f9f9;
}

.forgot-password {
  color: #4c51c6;
  font-size: 15px;
  font-style: normal;
  text-align: right;
  margin-top: 5px;
  font-weight: 400;
}

/* sign in button */
.sign-in .sign-in-btn {
  position: absolute;
  width: 340px;
  background-color: #3bd615;
  border-radius: 10px;
  top: 280px;
  height: 50px;
  color: white;
}

.sign-in-btn p {
  font-style: normal;
  font-weight: 550;
  font-size: 17px;
  color: #f9f9f9;
  letter-spacing: 0.03em;
}

/* right side of horizontal line */
.form-box .logo-text {
  /* position: absolute;
  right: 50px;
  top: 70px;
  height: 300px;
  width: 250px; */
}

.logo-text img {
  /* position: absolute; */
  /* left: 26px; */
  display: flex;
  width: auto;
  justify-content: center;
  align-items: center;
  height: 190px;
  /* width: 190px; */
}

.logo-text .auto-pulse {
  color: black;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  letter-spacing: 0.05em;
  font-family: "Inter", sans-serif;
  /* margin-top: 165px; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-text .text {
  font-size: 14px;
  color: black;
  /* width: 250px; */
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 550px) {
  hr.hr-line {
    display: none;
  }

  .form {
    height: 100px;
    background-color: #fff;
    padding-bottom: 0.3rem;
  }

  .form .form-box {
    position: relative;
    margin-top: 30%;
    left: 27px;
    right: 15px;
    padding: 50px 43px;
    width: 20rem;
    height: 25rem;
    border: 1px solid #e8e8e8;
    background: white;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  }

  .form-box .sign-in {
    left: 0;
    width: 320px;
  }

  .sign-in .sign-in-header {
    position: absolute;
    width: 200px;
    left: 60px;
  }

  .sign-in-input {
    padding: 10px;
  }

  .sign-in-input .input-user {
    width: 300px;
    height: 50px;
    border-radius: 10px;
    background: #f9f9f9;
  }

  .sign-in .sign-in-btn {
    width: 300px;
    margin-left: 10px;
  }

  .form-box .logo-text {
    display: none;
  }
}

@media screen and (max-width: 380px) {
  .form .form-box {
    position: relative;
    margin-top: 30%;
    justify-content: center;
    align-items: center;
    width: 18rem;
    height: 25rem;
    border: 1px solid #e8e8e8;
    background: white;
    border-radius: 10px;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
  }

  .sign-in .sign-in-btn {
    width: 250px;
    margin-left: 17px;
  }

  .sign-in-input .input-user {
    width: 265px;
    height: 40px;
    border-radius: 10px;
    background: #f9f9f9;
  }

  .sign-in .sign-in-header {
    position: absolute;
    width: 200px;
    left: 50px;
  }

  .sign-in-header span {
    font-size: 12px;
    left: 8%;
  }
}
