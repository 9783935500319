/* .card-1{
    display:flex;
    position: relative;
    height: 80%;
    width: 100%;
}

.card-1 .content{
    width: 50%;
    height: 40%;
    margin-top: 30px;
    margin-left: 70px;
}

.content p{
    color: #7c8db5;
} */

/* .containercp{
      max-height: calc(100vh - 20px);
      overflow-y: auto;
      padding-right: 16px;
      scrollbar-width: thin;
  }
 */

 @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.card {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 17px;
    box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
    font-family: "Montserrat", sans-serif;
    background-color: #fff;
    transition: all 0.3s ease;
    letter-spacing: 0.5px;
  }

  .dashboard-content{
    height: 100%;
    width: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    font-family: "Montserrat", sans-serif;
  }

  .card .dashboard-content .dashboard-box{
    display: flex;
    flex-direction: row;
  }
  
  /* .content {
    padding: 16px;
  } */
  
  .message-number {
    font-size: 24px;
    font-weight: bold;
  }
  
  .message-text {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
  }

  .message-text2 {
    font-size: 13px;
    color: #666;
    margin-top: 5px;
  }
  