@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.containert {
    max-height: calc(100vh - 150px); /* Adjust based on your header/footer height */
    overflow-y: auto;
    padding-right: 16px; /* To avoid scrollbar overlap */
    scrollbar-width: thin;
  }
  
.containert input{
    height: 1em;
    /* border: 1px solid rgba(59, 214, 21, 0.3);
    border-radius: 5px;
    background-color: #f9f9f9; */
}
.containert p{
    font-family: "Inter", sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.containert fieldset{
    border: 1px solid rgba(59, 214, 21, 0.3);
    border-radius: 5px;
    background-color: #f9f9f9;
    z-index: -1;
}
