/* .auto-pulse{
    color: black;
    font-weight: bold;
}

.auto-pulse strong{
    color: #3bd615;
}

.navbar{
    background-color:#f9f9f9;
}
 */


 /* .client-navbar{
  margin-left: 252px;
  width: calc(100% - 252px);
 } */


/* src/AppBar.css */
.app-bar {
  display: flex;
  flex-direction: row;
  background-color: white;
  padding: 10px 20px;
  /* border-bottom: 1px solid #e0e0e0; */
  box-shadow: 0px 4px 40px rgba(39, 32, 120, 0.1);
  justify-content: space-between;
}

.icon-home{
  font-size: 15px;
}

.top-breadcrumb {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-left: 5px;
}

.bottom-breadcrumb {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #333;
  margin-top: 5px;
}

.bottom-breadcrumb .icon.home {
  margin-right: 5px;
}

.bottom-breadcrumb span {
  margin-right: 5px;
}

.bottom-breadcrumb span.current {
  color: green;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 7px 0px;
  /* margin-top: 10px; */
}

.search {
  padding: 5px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 200px;
}

.icons {
  display: flex;
  align-items: center;
}

.icon {
  margin-left: 10px;
  cursor: pointer;
}

.icon .badge {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 10px;
  position: relative;
  top: -10px;
  right: -10px;
}

.profile {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}


.profile img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.profile .role {
  font-size: 12px;
  color: #777;
}


.dropdown-menu {
  position: absolute;
  top: 35px;
  right: 0;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: none;
  width: 150px;
  z-index: 9999;
  /* opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease; */
}

.profile:hover .dropdown-menu {
  display: block;
  animation: dropdownOpen 0.3s ease-in-out forwards;
}

@keyframes dropdownOpen {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-menu div {
  padding: 10px;
  cursor: pointer;
}

.dropdown-menu div:hover {
  background-color: #f0f0f0;
}

/* <style jsx>{`
  .actions {
    position: relative;
  }
  .profile {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .profile img {
    border-radius: 50%;
    margin-right: 10px;
  }
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: none;
  }
  .profile:hover .dropdown-menu {
    display: block;
  }
`}</style> */
